import * as React from "react";
import useDarkMode from "@fisch0920/use-dark-mode";
import { Switch } from "antd";

const DarkModeToggle = () => {
  const darkMode = useDarkMode(false);

  return (
    <Switch
      checked={darkMode.value}
      onChange={darkMode.toggle}
      checkedChildren="☀"
      unCheckedChildren="☾"
    />
  );
};

export default DarkModeToggle;
