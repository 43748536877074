import * as React from "react";
import { Link } from "gatsby";
import { Drawer } from "antd";
import { GithubOutlined } from "@ant-design/icons";
import { AiOutlineMenu } from "react-icons/ai";
import "./layout.css";
import DarkModeToggle from "./dark-mode-toggle";
import useDarkMode from "@fisch0920/use-dark-mode";

const Layout = ({ location, title, children }) => {
  const [menuVisible, setMenuVisible] = React.useState(false);
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;
  let header = !isRootPath ? (
    <span className="main-heading">
      <Link to="/" className="header-link-home" title="主页">
        {title}
      </Link>
    </span>
  ) : (
    <h4 className="main-heading">{title}</h4>
  );

  function handleMenu() {
    setMenuVisible(!menuVisible);
  }

  const links = [
    { path: "/", title: "Home" },
    { path: "/archive", title: "Archive" },
    { path: "/tags", title: "Tags" },
    { path: "/categories", title: "Categories" },
    { path: "/about", title: "About" },
    { path: "/lab", title: "Lab" },
    { path: "/pitfall", title: "Pitfall" },
    // { path: "/afreshjs", title: "afreshjs" },
  ];

  const darkMode = useDarkMode(false);

  return (
    <div className="global-container">
      <div className="global-wrapper" data-is-root-path={isRootPath}>
        <header className="global-header">
          <span>{header}</span>
          <div className="global-header-nav">
            {links.map((link) => {
              return (
                <Link key={link.path} to={link.path}>
                  {link.title}
                </Link>
              );
            })}
            <a
              className="Docs"
              href="https://docs.zhouweibin.top/docs/awesome-dev/工具/"
              target="_blank"
              title="我的个人文档"
            >
              Docs
            </a>
            <a
              className="global-github"
              href="https://github.com/GitHubJackson"
              target="_blank"
            >
              <GithubOutlined />
            </a>
            <DarkModeToggle mode={darkMode} />
          </div>
        </header>
        <main className="global-content">{children}</main>
        <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.com">Gatsby</a>
        </footer>
        <div className="hover-menu" onClick={handleMenu}>
          <AiOutlineMenu />
        </div>
        <Drawer
          title="menu"
          width="140px"
          placement="left"
          closable={false}
          onClose={handleMenu}
          visible={menuVisible}
        >
          {links.map((link) => {
            return (
              <div style={{ marginBottom: 8 }}>
                <Link key={link.path} to={link.path}>
                  {link.title}
                </Link>
              </div>
            );
          })}
        </Drawer>
      </div>
    </div>
  );
};

export default Layout;
